



































































.projects {
  display: flex;
  flex-grow: 1;
  overflow: hidden;

  &__list {
    border-right: 1px solid #e7e7e7;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 300px;
    max-width: 300px;
  }

  &__item {
    display: block;
    padding: 20px 32px;
    border-bottom: 1px solid #e7e7e7;
    font-weight: 500;
    max-width: 380px;
    min-width: 300px;
    text-decoration: none;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .router-link-active {
    background-color: #f5f5f5;
  }
}
